<template>
  <div v-show="!showModal && !showUserList" class="role">
    <a-spin :spinning="loading">
		<a-form ref="form" name="form" :model="formState"  @finish="onSearch">
			<a-row>
				<a-form-item label="角色名称" name="title">
					<a-input v-model:value="formState.title" placeholder="请输入角色名称"/>
				</a-form-item>
			</a-row>
			
			<a-row>
				<a-col :span="18">
					<a-button v-permission="['system_manage_role_add']" type="primary" @click="onAdd">新建</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button type="primary" html-type="submit" style="margin-right: 20px;">搜索</a-button>
					<a-button @click="reset">重置</a-button>
				</a-col>
			</a-row>
		</a-form>
      <a-table
	  style="margin-top: 10px;"
        :dataSource="dataList"
        :pagination="pagination"
        :columns="columns"
		rowKey="id"
        size="middle"
      >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'createTime'">
          {{ transDateTime(record.createTime) }}
        </template>
          <template v-if="column.key === 'operate'">
            <div>
            <a-dropdown>
              <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
              <template #overlay>
                <a-menu>
                  <div v-permission="['system_manage_role_edit']" @click="onEdit(record)">
                    <a-menu-item>
                      <a>修改</a>
                    </a-menu-item>
                  </div>
                  <div v-permission="['system_manage_role_bind']" @click="onSee(record)">
                    <a-menu-item>
                      <a>查看角色关联用户</a>
                    </a-menu-item>
                  </div>
                  <div v-permission="['system_manage_role_power']" @click="onPower(record)">
                    <a-menu-item>
                      <a>配置权限</a>
                    </a-menu-item>
                  </div>
                  <div v-permission="['system_manage_role_delete']" @click="onDelete(record)">
                    <a-menu-item>
                      <a>删除</a>
                    </a-menu-item>
                  </div>
                </a-menu>
              </template>
            </a-dropdown>
            </div>
          </template>
        </template>
      </a-table>
	  
	  <a-modal :title="isEdit ? '编辑角色名称' : '新增角色'" v-model:visible="editModalVisible" @ok="onEditOk">
		  <a-spin :spinning="loading">
				<a-form ref="editForm" name="editForm" :model="editModelRef">
					<a-form-item label="角色名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="editModelRef.title" placeholder="请输入角色名称"></a-input>
					</a-form-item>
				</a-form>
		  </a-spin>
	  </a-modal>
    </a-spin>
  </div>
  <power v-if="showModal" :isEdit="isEdit" :name="name" :id="id" @back="onBack"></power>
  <userList v-else-if="showUserList" :id="id" @back="onBack"></userList>
</template>

<script>
import * as systemApi from '@/service/modules/system';
import { Icon } from "@/components/icon/icon.js";
import power from './power.vue'; 
import userList from './userList.vue';
export default {
  components: { Icon, power, userList },
  data() {
    return {
      loading: false,
	  formState: {},
	  searchData: {},
      dataList: [],
      columns: [
        {
          title: "角色名称",
          dataIndex: "title",
        },
        // {
        //   title: "角色描述",
        //   dataIndex: "description",
        //   width: "20%",
        // },
        {
          title: "创建时间",
          dataIndex: "createTime",
          key: "createTime"
        }, {
			title: '创建者',
			dataIndex: 'createUserName'
		},
        {
          title: "操作",
          dataIndex: "operate",
          key: 'operate'
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 20,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
	  editModalVisible: false,
	  editModelRef: {},
	  showUserList: false,
	  showModal: false,
	  id: 0,
	  isEdit: false,
	  name: ''
    }
  },
  created() {
    this.getData();
  },
  methods: {
	  onBack(isRef) {
		  this.showModal = false;
		  this.showUserList = false;
		  if (isRef) {
			  this.getData();
		  }
	  },
    onSearch() {
      this.pagination.current = 1;
	  this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
	reset() {
		this.$refs.form.resetFields();
		this.onSearch();
	},
    getData() {
      this.loading = true;
      systemApi.roleList({
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
		...this.searchData
      }).then(res => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list;
          this.pagination.total = res.data.totalCount;
        }
      })
    },
    onAdd() {
		this.id = 0;
		this.isEdit = false;
		this.editModalVisible = true;
      // this.$router.push({path: '/system/systemRoot/role/add'})
    },
    onEdit(item) {
		this.id = item.id;
		this.editModelRef.title = item.title;
		this.isEdit = true;
		this.editModalVisible = true;
		// this.showModal = true;
      // this.$router.push({path: '/system/systemRoot/role/edit', query: { id: item.id }})
    },
	onPower(item) {
		this.id = item.id;
		this.name = item.title;
		this.showModal = true;
	},
	onSee(item) {
		this.id = item.id;
		this.showUserList = true;
	},
	onEditOk() {
		this.$refs.editForm.validateFields().then(async () => {
			this.loading = true;
			let ret;
			if (this.isEdit && this.id) {
				ret = await systemApi.editRole({
					title: this.editModelRef.title,
					id: this.id
				});
			} else {
				ret = await systemApi.addRole({
					title: this.editModelRef.title,
				})
			}
			this.loading = false;
			if (ret.code === 200) {
				this.editModalVisible = false;
				this.$message.success('操作成功');
				this.getData();
			}
		})
	},
    onDelete(item) {
      this.$confirm({
        title: '确定删除吗？',
        onOk: () => {
          this.loading = true;
          systemApi.deleteRole({id: item.id}).then(res => {
            this.loading = false;
            if (res.code == 200) {
              this.$message.success('操作成功');
              this.getData();
            }
          })
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
.role {
  // background: red;
}
.a-primaryBtn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 0;
}
</style>
